(function() {
    'use strict';

    angular.module('ionicNoteCategories', [
        'ionic',
        'ionicAlert',
        'ionicConfirmation',
        'ionicData',
        'ionicDataTable',
        'ionicFilter',
        'ionicLogin',
        'uabDefaultVariable',
        'uabEnvironment',
        'uabMoment',
        'uabRedirect'
    ]);
})();
(function() {
    'use strict';

    angular.module('ionicNoteCategories').service(
        'NoteCategoryCreateService',
        NoteCategoryCreateService
    );

    NoteCategoryCreateService.$inject = [
        'DefaultVariableService',
        'ProjectService',
        'RequestManagementService',
        'NoteCategoryListService'
    ];

    function NoteCategoryCreateService(
        DefaultVariableService,
        ProjectService,
        RequestManagementService,
        NoteCategoryListService
    ) {
        var NoteCategoryCreateService = this;

        NoteCategoryCreateService.createNoteCategory = createNoteCategory;
        function createNoteCategory(newNoteCategory) {
            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'note_categories');
            options = RequestManagementService.setAction(options, 'add');

            options = RequestManagementService.setData(
                options,
                newNoteCategory
            );

            return ListManager.create(options);
        }

        NoteCategoryCreateService.reset = reset;
        function reset() {

        }

        var ListManager = NoteCategoryListService.getListManager();

        NoteCategoryCreateService.reset();

        return NoteCategoryCreateService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicNoteCategories').service(
        'NoteCategoryEditService',
        NoteCategoryEditService
    );

    NoteCategoryEditService.$inject = [
        'DataManagementService',
        'DefaultVariableService',
        'LocalStorageService',
        'LoginService',
        'RequestManagementService'
    ];

    function NoteCategoryEditService(
        DataManagementService,
        DefaultVariableService,
        LocalStorageService,
        LoginService,
        RequestManagementService
    ) {
        var NoteCategoryEditService = this;

        NoteCategoryEditService.deleteNoteCategory = deleteNoteCategory;
        function deleteNoteCategory(note_category) {
            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'note_categories');
            options = RequestManagementService.setAction(options, 'delete', note_category.id);

            return DataManagementService.request(options);
        }

        NoteCategoryEditService.editNoteCategory = editNoteCategory;
        function editNoteCategory(note_category) {
            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'note_categories');
            options = RequestManagementService.setAction(options, 'edit', note_category.id);

            options = RequestManagementService.setData(options, note_category);

            return DataManagementService.request(options).then(
                function(data) {
                    if (data) {
                        note_category = DefaultVariableService.getObject(
                            data.note_categories
                        );

                        return note_category;
                    }

                    return false;
                }
            );
        }

        NoteCategoryEditService.reset = reset;
        function reset() {

        }

        NoteCategoryEditService.reset();

        return NoteCategoryEditService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicNoteCategories').service('NoteCategoryListService', NoteCategoryListService);

    NoteCategoryListService.$inject = [
        'DefaultVariableService',
        'ListManagementService',
        'ProjectService',
        'RequestManagementService'
    ];

    function NoteCategoryListService(
        DefaultVariableService,
        ListManagementService,
        ProjectService,
        RequestManagementService
    ) {
        var NoteCategoryListService = this;

        NoteCategoryListService.getListManager = getListManager;
        function getListManager() {
            return ListManager;
        }

        NoteCategoryListService.getPage = getPage;
        function getPage(options) {
            options = RequestManagementService.getRequest(options);

            options = RequestManagementService.setModel(options, 'note_categories');
            options = RequestManagementService.setAction(options, 'get');

            return ListManager.getPage(options);
        }

        NoteCategoryListService.reset = reset;
        function reset() {
            ListManager.reset();
        }

        var ListManager = ListManagementService.getListManager(
            NoteCategoryListService.getPage
        );

        NoteCategoryListService.reset();

        return NoteCategoryListService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicNoteCategories').controller(
        'NoteCategoryCreateController',
        NoteCategoryCreateController
    );

    NoteCategoryCreateController.$inject = [
        '$scope',
        'AlertService',
        'DefaultVariableService',
        'NoteCategoryCreateService'
    ];

    function NoteCategoryCreateController(
        $scope,
        AlertService,
        DefaultVariableService,
        NoteCategoryCreateService
    ) {
        var NoteCategoryCreateController = this;

        $scope.$watch(
            'onCancel',
            function(onCancel) {
                NoteCategoryCreateController.onCancel = DefaultVariableService.get(
                    onCancel,
                    false
                );
            }.bind(NoteCategoryCreateController)
        );

        $scope.$watch(
            'onCreate',
            function(onCreate) {
                NoteCategoryCreateController.onCreate = DefaultVariableService.getPromise(
                    onCreate
                );
            }.bind(NoteCategoryCreateController)
        );
        
        NoteCategoryCreateController.cancel = cancel;
        function cancel() {
            NoteCategoryCreateController.onCancel();
        }

        NoteCategoryCreateController.createNoteCategory = createNoteCategory;
        function createNoteCategory(newNoteCategory) {
            newNoteCategory = DefaultVariableService.get(
                newNoteCategory,
                NoteCategoryCreateController.newNoteCategory
            );
            
                NoteCategoryCreateController.isCreatingNoteCategory = true;

            return NoteCategoryCreateService.createNoteCategory(newNoteCategory).then(
                function(newNoteCategory) {
                    if (newNoteCategory) {
                        NoteCategoryCreateController.resetNewNoteCategory();

                        NoteCategoryCreateController.onCreate(newNoteCategory);

                        AlertService.addMessage('NOTE CATEGORY CREATED.');
                    }

                    return newNoteCategory;
                }
            ).finally(
                function() {
                    NoteCategoryCreateController.isCreatingNoteCategory = false;
                }
            );
        }

        NoteCategoryCreateController.reset = reset;
        function reset() {
            NoteCategoryCreateController.resetNewNoteCategory();

            NoteCategoryCreateController.showCreator = false;
        }

        NoteCategoryCreateController.resetNewNoteCategory = resetNewNoteCategory;
        function resetNewNoteCategory() {
            NoteCategoryCreateController.newNoteCategory = {};
        }

        NoteCategoryCreateController.toggleCreator = toggleCreator;
        function toggleCreator() {
           NoteCategoryCreateController.onCancel();
        }

        NoteCategoryCreateController.init = init;
        function init() {
            NoteCategoryCreateController.reset();
        }

        NoteCategoryCreateController.init();
    }
})();
(function() {
    'use strict';

    angular.module('ionicNoteCategories').directive(
        'ionicNoteCategoryCreate',
        ionicNoteCategoryCreate
    );

    function ionicNoteCategoryCreate() {
        return {
            controller:   'NoteCategoryCreateController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope: {
                onCreate: '=',
                onCancel: '='
            },
            template:'<ion-list class="item list padding-horizontal"><div class="row"><label class="item item-input item-floating-label col-12"><span class="input-label">NAME</span> <input type="text" placeholder="USERNAME" data-ng-model="ctrl.newNoteCategory.name"></label></div><div class="row"><button class="button button-full accept-button" data-ng-class="{ \'col-xl-6\': ctrl.onCancel }" data-ng-click="ctrl.createNoteCategory()" data-ng-disabled="ctrl.isCreatingNoteCategory">{{ ctrl.isCreatingNoteCategory ? \'CREATING...\' : \'CREATE\' }}</button> <button data-ng-if="ctrl.onCancel" class="button button-full decline-button" data-ng-class="{ \'col-xl-6\': ctrl.onCancel }" data-ng-click="ctrl.cancel()" data-ng-disabled="ctrl.isCreatingNoteCategory">CANCEL</button></div></ion-list>'
        };
    }
})();
(function() {
    'use strict';

    angular.module('ionicNoteCategories').controller(
        'NoteCategoryEditController',
        NoteCategoryEditController
    );

    NoteCategoryEditController.$inject = [
        'AlertService',
        'DefaultVariableService',
        'IonicConfirmationService',
        'NoteCategoryEditService',
        'LoginService',
        'MomentService',
        '$scope'
    ];

    function NoteCategoryEditController(
        AlertService,
        DefaultVariableService,
        IonicConfirmationService,
        NoteCategoryEditService,
        LoginService,
        MomentService,
        $scope
    ) {
        var NoteCategoryEditController = this;

        $scope.$watch(
            'onCancel',
            function(onCancel) {
                NoteCategoryEditController.onCancel = DefaultVariableService.get(
                    onCancel,
                    false
                );
            }
        );

        $scope.$watch(
            'onDelete',
            function(onDelete) {
                NoteCategoryEditController.onDelete = DefaultVariableService.getPromise(
                    onDelete
                );
            }
        );

        $scope.$watch(
            'onEdit',
            function(onEdit) {
                NoteCategoryEditController.onEdit = DefaultVariableService.getPromise(
                    onEdit
                );
            }
        );

        $scope.$watch(
            'noteCategory',
            function(noteCategory) {
                NoteCategoryEditController.loadNoteCategory(noteCategory);
            }
        );

        NoteCategoryEditController.cancel = cancel;
        function cancel() {
            if (NoteCategoryEditController.onCancel) {
                NoteCategoryEditController.onCancel();
            }
        }

        NoteCategoryEditController.clear = clear;
        function clear() {
            NoteCategoryEditService.reset();
            NoteCategoryEditController.reset();
        }

        NoteCategoryEditController.forceRefresh = forceRefresh;
        function forceRefresh() {
            NoteCategoryEditController.clear();
            NoteCategoryEditController.init();
        }

        NoteCategoryEditController.deleteNoteCategory = deleteNoteCategory;
        function deleteNoteCategory() {
            NoteCategoryEditController.isDeletingNoteCategory = true;

            return IonicConfirmationService.createConfirmation(
                'ARE YOU SURE YOU WANT TO DELETE ' + NoteCategoryEditController.noteCategory.name + '?',
                'DELETE',
                'CANCEL'
            ).then(
                function(canDelete) {
                    if (canDelete) {
                        return NoteCategoryEditService.deleteNoteCategory(
                            NoteCategoryEditController.noteCategory
                        ).then(
                            function(noteCategory) {
                                if (noteCategory) {
                                    AlertService.addMessage(
                                        'NOTE CATEGORY DELETED.'
                                    );

                                    NoteCategoryEditController.onEdit();

                                    NoteCategoryEditController.forceRefresh();
                                }

                                return noteCategory;
                            }
                        );
                    }

                    return canDelete;
                }
            ).finally(
                function() {
                    NoteCategoryEditController.isDeletingNoteCategory = false;
                }
            );
        }

        NoteCategoryEditController.editNoteCategory = editNoteCategory;
        function editNoteCategory() {
            NoteCategoryEditController.isEditingNoteCategory = true;

            return NoteCategoryEditService.editNoteCategory(
                NoteCategoryEditController.noteCategory
            ).then(
                function(noteCategory) {
                    if (noteCategory) {
                        AlertService.addMessage(NoteCategoryEditController.onEditMessage);

                        NoteCategoryEditController.onEdit(noteCategory);

                        NoteCategoryEditController.forceRefresh();
                    }

                    return noteCategory;
                }
            ).finally(
                function() {
                    NoteCategoryEditController.isEditingNoteCategory = false;
                }
            );
        }

        NoteCategoryEditController.loadNoteCategory = loadNoteCategory;
        function loadNoteCategory(noteCategory) {
            NoteCategoryEditController.noteCategory = noteCategory;
        }

        NoteCategoryEditController.reset = reset;
        function reset() {
            NoteCategoryEditController.editButtonText = DefaultVariableService.getString(
                NoteCategoryEditController.editButtonText,
                'EDIT'
            );

            NoteCategoryEditController.editingButtonText = DefaultVariableService.getString(
                NoteCategoryEditController.editButtonText,
                'EDITING...'
            );

            NoteCategoryEditController.isEditingNoteCategory = false;

            NoteCategoryEditController.isLoadingNoteCategory = false;

            NoteCategoryEditController.onEditMessage = DefaultVariableService.getString(
                NoteCategoryEditController.onEditMessage,
                'NOTE CATEGORY UPDATED.'
            );
        }

        NoteCategoryEditController.init = init;
        function init() {
            NoteCategoryEditController.reset();
        }

        LoginService.register(NoteCategoryEditController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicNoteCategories').directive('ionicNoteCategoryEdit', ionicNoteCategoryEdit);

    function ionicNoteCategoryEdit() {
        return {
            bindToController: {
                editButtonText:    '@',
                editingButtonText: '@',
                onEditMessage:     '@',
                tagLabel:          '@'
            },
            controller:   'NoteCategoryEditController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope: {
                onCancel:     '=',
                onDelete:     '=',
                onEdit:       '=',
                noteCategory: '='
            },
            template:'<ion-list data-ng-if="!ctrl.isLoadingNoteCategory" class="item list padding-horizontal"><div class="row"><label class="item item-input item-floating-label col-12"><span class="input-label">NAME</span> <input type="text" placeholder="NAME" data-ng-model="ctrl.noteCategory.name"></label></div><div class="row"><button class="button button-full accept-button col-xl-4" data-ng-click="ctrl.editNoteCategory()" data-ng-disabled="ctrl.isDeletingNoteCategory || ctrl.isEditingNoteCategory">{{ ctrl.isEditingNoteCategory ? ctrl.editingButtonText : ctrl.editButtonText }}</button> <button class="button button-full decline-button col-xl-4" data-ng-click="ctrl.deleteNoteCategory()" data-ng-disabled="ctrl.isDeletingNoteCategory || ctrl.isEditingNoteCategory">{{ ctrl.isDeletingNoteCategory ? \'DELETING...\' : \'DELETE\' }}</button> <button class="button button-full col-xl-4" data-ng-click="ctrl.cancel()" data-ng-disabled="ctrl.isDeletingNoteCategory || ctrl.isEditingNoteCategory">CANCEL</button></div></ion-list><span data-ng-if="ctrl.isLoadingNoteCategory"><ion-spinner class="centerSpinner"></ion-spinner></span>'
        };
    }
})();
(function() {
    'use strict';

    angular.module('ionicNoteCategories').controller(
        'NoteCategoryFilterController',
        NoteCategoryFilterController
    );

    NoteCategoryFilterController.$inject = [
        'AlertService',
        'DefaultVariableService',
        'LoginService',
        '$scope',
        'NoteCategoryListService'
    ];

    function NoteCategoryFilterController(
        AlertService,
        DefaultVariableService,
        LoginService,
        $scope,
        NoteCategoryListService
    ) {
        var NoteCategoryFilterController = this;

        $scope.$watch(
            'onSelect',
            function(onSelect) {
                NoteCategoryFilterController.onSelect = DefaultVariableService.getPromise(
                    onSelect
                );
            }
        );

        $scope.$watch(
            'target',
            function(target) {
                NoteCategoryFilterController.target = DefaultVariableService.get(
                    target,
                    false
                );
            }
        );

        NoteCategoryFilterController.clear = clear;
        function clear() {
            NoteCategoryListService.reset();
            NoteCategoryFilterController.reset();
        }

        NoteCategoryFilterController.loadNoteCategories = loadNoteCategories;
        function loadNoteCategories(options) {
            NoteCategoryFilterController.isLoadingCategories = true;

            return NoteCategoryListService.getPage(
                options
            ).then(
                function(data) {
                    if (data) {
                        NoteCategoryFilterController.noteCategories = DefaultVariableService.getArray(
                            data.note_categories
                        );

                        NoteCategoryFilterController.count = DefaultVariableService.getInteger(
                            data.count,
                            0
                        );
                    }

                    return data;
                }
            ).finally(
                function() {
                    NoteCategoryFilterController.isLoadingCategories = false;
                }
            );
        }

        NoteCategoryFilterController.selectNoteCategories = selectNoteCategories;
        function selectNoteCategories(noteCategory) {
            NoteCategoryFilterController.onSelect(noteCategory);
        }

        NoteCategoryFilterController.reset = reset;
        function reset() {
            NoteCategoryFilterController.isLoadingCategories = false;

            NoteCategoryFilterController.resetNoteCategory();
        }

        NoteCategoryFilterController.resetNoteCategory = resetNoteCategory;
        function resetNoteCategory() {
            NoteCategoryFilterController.noteCategory = DefaultVariableService.getObject();

            NoteCategoryFilterController.loadNoteCategories();
        }

        NoteCategoryFilterController.toggleCreator = toggleCreator;
        function toggleCreator() {
            NoteCategoryFilterController.showCreator = !NoteCategoryFilterController.showCreator;
        }

        NoteCategoryFilterController.init = init;
        function init() {
            NoteCategoryFilterController.reset();
            NoteCategoryFilterController.loadNoteCategories();
        }

        LoginService.register(NoteCategoryFilterController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicNoteCategories').directive(
        'ionicNoteCategoryFilter',
        ionicNoteCategoryFilter
    );

    function ionicNoteCategoryFilter() {
        return {
            controller:   'NoteCategoryFilterController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope: {
                onSelect: '=',
                target:   '='
            },
            template:'<div><ionic-filter on-filter="ctrl.loadNoteCategories" on-select="ctrl.selectNoteCategories" target="ctrl.target" title="name" toggle-message="SEARCH CATEGORIES"></ionic-filter></div>'
        };
    }
})();
(function() {
    'use strict';

    angular.module('ionicNoteCategories').controller(
        'NoteCategoryManagementController',
        NoteCategoryManagementController
    );

    NoteCategoryManagementController.$inject = [
        'DefaultVariableService',
        'EnvironmentService',
        'LoginService',
        'RedirectService',
        '$scope',
        'NoteCategoryListService'
    ];

    function NoteCategoryManagementController(
        DefaultVariableService,
        EnvironmentService,
        LoginService,
        RedirectService,
        $scope,
        NoteCategoryListService
    ) {
        var NoteCategoryManagementController = this;

        $scope.$watch(
            'allowCreate',
            function(allowCreate) {
                NoteCategoryManagementController.allowCreate = DefaultVariableService.get(
                    allowCreate,
                    true
                );
            }.bind(NoteCategoryManagementController)
        );

        $scope.$watch(
            'onSelect',
            function(onSelect) {
                NoteCategoryManagementController.onSelect = DefaultVariableService.get(
                    onSelect,
                    false
                );
            }.bind(NoteCategoryManagementController)
        );

        NoteCategoryManagementController.clear = clear;
        function clear() {
            NoteCategoryManagementController.reset();
            NoteCategoryListService.reset();
        }

        NoteCategoryManagementController.onCreateUser = onCreateUser;
        function onCreateUser() {
            NoteCategoryManagementController.toggleCreator();
            NoteCategoryManagementController.getPage();
        }

        NoteCategoryManagementController.onCancel = onCancel;
        function onCancel() {
            NoteCategoryManagementController.toggleCreator();
        }

        NoteCategoryManagementController.onEditNote = onEditNote;
        function onEditNote() {
            NoteCategoryManagementController.resetNoteCategory();

            NoteCategoryManagementController.getPage();
        }

        NoteCategoryManagementController.getPage = getPage;
        function getPage(options) {
            NoteCategoryManagementController.isLoadingNotes = true;

            return NoteCategoryListService.getPage(
                options
            ).then(
                function(data) {
                    if (data) {
                        NoteCategoryManagementController.noteCategories = DefaultVariableService.getArray(
                            data.users
                        );

                        NoteCategoryManagementController.count = DefaultVariableService.getInteger(
                            data.count,
                            0
                        );
                    }

                    return data;
                }
            ).finally(
                function() {
                    NoteCategoryManagementController.isLoadingNotes = false;
                }
            );
        }

        NoteCategoryManagementController.selectNote = selectNote;
        function selectNote(note) {
            if (NoteCategoryManagementController.onSelect) {
                NoteCategoryManagementController.onSelect(note);
            } else {
                return NoteCategoryManagementController.toggleEditor(note);
            }
        }

        NoteCategoryManagementController.setUrl = setUrl;
        function setUrl(action, noteCategoryId) {
            RedirectService.setUrl(
                {
                    action: action,
                    noteCategoryId: noteCategoryId
                }
            );
        }

        NoteCategoryManagementController.toggleCreator = toggleCreator;
        function toggleCreator() {
            NoteCategoryManagementController.showCreator = !NoteCategoryManagementController.showCreator;

            var action = '';
            if (NoteCategoryManagementController.showCreator) {
                action = 'create';
            }

            NoteCategoryManagementController.setUrl(action);
        }

        NoteCategoryManagementController.toggleEditor = toggleEditor;
        function toggleEditor(note) {
            note = DefaultVariableService.getObject(note);

            note.showEditor = DefaultVariableService.toggle(
                note.showEditor,
                false
            );

            var action = '';
            if (note.showEditor && note.id !== 0) {
                NoteCategoryManagementController.noteCategory = note;

                action = 'edit';
            } else {
                NoteCategoryManagementController.resetNoteCategory();
            }

            if (NoteCategoryManagementController.noteCategory.id === 0) {
                NoteCategoryManagementController.setUrl(action);
            } else {
                NoteCategoryManagementController.setUrl(action, NoteCategoryManagementController.noteCategory.id);
            }
        }

        NoteCategoryManagementController.reset = reset;
        function reset() {
            NoteCategoryManagementController.columns = [
                {
                    position: 1,
                    title:    'ID',
                    key:      'id'
                },
                {
                    position: 2,
                    title:    'NAME',
                    key:      'name'
                }
            ];

            NoteCategoryManagementController.isLoadingNotes = false;

            NoteCategoryManagementController.resetNoteCategory();

            NoteCategoryManagementController.showCreator = false;

            NoteCategoryManagementController.noteCategory = DefaultVariableService.getObject();

            NoteCategoryManagementController.noteCategories = [];

            NoteCategoryManagementController.route();
        }

        NoteCategoryManagementController.resetNoteCategory = resetNoteCategory;
        function resetNoteCategory() {
            NoteCategoryManagementController.noteCategory = DefaultVariableService.getObject();
        }

        NoteCategoryManagementController.route = route;
        function route() {
            var action = EnvironmentService.get('action', false);

            if (DefaultVariableService.isString(action)) {
                action = action.toLowerCase();
            }

            if (action === 'create') {
                NoteCategoryManagementController.toggleCreator();
            } else if (action === 'edit') {
                var noteCategoryId = DefaultVariableService.getInteger(
                    EnvironmentService.get('noteCategoryId', 0),
                    0
                );

                if (noteCategoryId !== 0) {
                    NoteCategoryManagementController.toggleEditor({id: noteCategoryId});
                } else {
                    action = false;
                }
            }

            if (!action) {
                NoteCategoryManagementController.setUrl();
            }
        }

        NoteCategoryManagementController.init = init;
        function init() {
            NoteCategoryManagementController.reset();
            NoteCategoryManagementController.getPage();
        }

        LoginService.register(NoteCategoryManagementController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicNoteCategories').directive('ionicNoteCategoryManagement', ionicNoteCategoryManagement);

    function ionicNoteCategoryManagement() {
        return {
            controller:   'NoteCategoryManagementController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope: {
                allowCreate: '=',
                onSelect:    '='
            },
            template:'<div data-ng-if="ctrl.noteCategory.id === 0 && !ctrl.showCreator"><div data-ng-if="ctrl.allowCreate"><button class="button button-full accept-button" data-ng-click="ctrl.toggleCreator()">CREATE NOTE</button></div><ionic-data-table columns="ctrl.columns" on-request="ctrl.getPage" on-select="ctrl.selectNote"></ionic-data-table></div><ionic-note-category-edit data-ng-if="ctrl.noteCategory.id !== 0" on-cancel="ctrl.toggleEditor" on-edit="ctrl.onEditNote" on-delete="ctrl.deleteNoteCategory" note-category="ctrl.noteCategory"></ionic-note-category-edit><div data-ng-if="ctrl.showCreator"><ionic-note-category-create on-cancel="ctrl.onCancel" on-create="ctrl.onCreateUser"></ionic-note-category-create></div>'
        };
    }
})();